import { apiReportToAppReport } from "common/services/api";
import React from "react";
import { connect } from "react-redux";
import Checks, { getMarkingCodeIcon } from "../checks";
import styles from "./styles.module.css";
import {
  selectUser,
  selectFaults,
  selectFaultsAsArray,
} from "common/ducks/api";
import {
  cleanFaultName,
  getInitials,
  getStyleName,
} from "common/modules/utils";
import { getISOWeek } from "date-fns";
import {
  makeReportTyreChecksDefault,
  RECTIFICATION_DEFAULT_STATE,
  REPORT_DEFAULT_STATE,
} from "common/modules/default-states";
import { dateToDDMMYYYY } from "common/modules/date-utils";

const MakeCheckedComponent = ({ name, date, title }) => (
  <div className={styles.checkedContainer}>
    <span className={styles.infoFieldLabel}>{title}</span>
    <span className={styles.infoFieldValue}>{name}</span>
    <span className={styles.infoFieldLabel}> on </span>
    <span className={styles.infoFieldValue}>
      {new Date(date).toDateString()}
    </span>
  </div>
);

const MakeInfoComponent = ({ label, value }) => (
  <div style={{ gridArea: getStyleName(label) }}>
    <span className={styles.infoFieldLabel}>{label}</span>
    <span className={styles.infoFieldValue}>{value}</span>
  </div>
);

const getChecksFontSize = (length) => {
  const standardFontSize = 0.8;
  const upperLettersLimit = 50;
  const reductionRate = 0.35;
  const overTheLimitAmount = length / upperLettersLimit;
  const fontSize = Math.min(
    standardFontSize,
    (standardFontSize * reductionRate) / (overTheLimitAmount * 0.34)
  );
  return `${fontSize}rem`;
};

const MakeTyres = ({ tyres }) => {
  const onVehicleTyres = tyres.slice(0, tyres.length - 1);
  const offVehicleTyre = tyres[tyres.length - 1];
  return (
    <div className={styles.tyresContainer}>
      <div className={styles.tyreCheckTitle}>Tread wear / pressure</div>
      <div className={styles.tyreChecksContainer}>
        <div className={styles.onVehicleTyres}>
          {onVehicleTyres?.map((tyre) => (
            <div
              key={tyre.tyre_number}
              className={styles.onVehicleTyre}
              style={{ gridArea: `tyre${tyre.tyre_number}` }}
            >
              <div className={styles.tyreInputs}>
                <div className={styles.tyreInput}>{tyre.mm}</div>
                <div className={styles.tyreInput}>{tyre.psi}</div>
              </div>
              <div className={styles.tyreLabels}>
                <div className={styles.tyreLabel}>mm</div>
                <div className={styles.tyreLabel}>psi</div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={styles.offVehicleTyres}
          style={{ gridArea: `tyre${tyres.length - 1}` }}
        >
          <div className={styles.rotated}>
            <div className={styles.tyreInputs}>
              <div className={styles.tyreInput}>{offVehicleTyre.mm}</div>
              <div className={styles.tyreInput}>{offVehicleTyre.psi}</div>
            </div>
            <div className={styles.tyreLabels}>
              <div className={styles.tyreLabel}>mm</div>
              <div className={styles.tyreLabel}>psi</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MakeChecksInCategories = ({ checks, faults, tyres }) => {
  const categories = [];
  const categoryNames = [];
  const categoriesPage2 = [];
  const categoryNamesPage2 = [];
  let currentCategory = null;

  const NUM_PAGE_2_CHECKS = 18;

  // Iterate through 1st page checks
  for (let i = 0; i < checks.length - NUM_PAGE_2_CHECKS; i++) {
    // When change of category
    if (faults[checks[i].fault].category.id !== currentCategory) {
      currentCategory = faults[checks[i].fault].category.id;
      categories.push([]);
      categoryNames.push(faults[checks[i].fault].category.name);
    }
    categories[categories.length - 1].push(
      <div className={styles.check} key={i}>
        <div
          style={{
            fontSize:
              faults[checks[i].fault].name.length > 30
                ? getChecksFontSize(faults[checks[i].fault].name.length)
                : "1em",
            paddingLeft: faults[checks[i].fault].name.includes("BRAKES - ")
              ? "1rem"
              : 0,
          }}
          className={styles.fault}
        >
          {cleanFaultName(faults[checks[i].fault].name)}
        </div>
        <div className={styles.mc}>
          {getMarkingCodeIcon(checks[i].marking_code)}
        </div>
        <div className={styles.im}>{faults[checks[i].fault].im_no}</div>
      </div>
    );
  }

  categoriesPage2.push([]);
  categoryNamesPage2.push("Under alongside vehicle (continued)");

  for (let i = checks.length - NUM_PAGE_2_CHECKS; i < checks.length; i++) {
    if (faults[checks[i].fault].category.id !== currentCategory) {
      currentCategory = faults[checks[i].fault].category.id;
      categoriesPage2.push([]);
      categoryNamesPage2.push(faults[checks[i].fault].category.name);
    }
    categoriesPage2[categoriesPage2.length - 1].push(
      <div className={styles.check} key={i}>
        <div
          style={{
            fontSize:
              faults[checks[i].fault].name.length > 30
                ? getChecksFontSize(faults[checks[i].fault].name.length)
                : "1em",
            paddingLeft: faults[checks[i].fault].name.includes("BRAKES - ")
              ? "1rem"
              : 0,
          }}
          className={styles.fault}
        >
          {cleanFaultName(faults[checks[i].fault].name)}
        </div>
        <div className={styles.mc}>
          {getMarkingCodeIcon(checks[i].marking_code)}
        </div>
        <div className={styles.im}>{faults[checks[i].fault].im_no}</div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.checksContainer}>
        {categories.map((category, index) => (
          <div
            key={index}
            className={styles.categoryContainer}
            style={{ gridArea: getStyleName(categoryNames[index]) }}
          >
            <div className={styles.categoryHeading}>
              <div className={styles.categoryName}>{categoryNames[index]}</div>
              <div className={styles.mcHeading}>MC</div>
              <div className={styles.imHeading}>IM</div>
            </div>
            {category.map((check) => check)}
          </div>
        ))}
      </div>

      <div className={styles.checksContainerPage2}>
        {categoriesPage2.map((category, index) => (
          <div
            key={index}
            className={styles.categoryContainer}
            style={{ gridArea: getStyleName(categoryNamesPage2[index]) }}
          >
            <div className={styles.categoryHeading}>
              <div className={styles.categoryName}>
                {categoryNamesPage2[index]}
              </div>
              <div className={styles.mcHeading}>MC</div>
              <div className={styles.imHeading}>IM no.</div>
            </div>
            {category.map((check) => check)}

            {getStyleName(categoryNamesPage2[index]) == "tyres" && (
              <MakeTyres tyres={tyres} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const MakeRectifications = ({ rectifications }) => {
  const blankRectification = { ...RECTIFICATION_DEFAULT_STATE };
  const numOfWantedRectifications = 17;
  const numOfRectifications = rectifications
    ? Object.keys(rectifications).length
    : 0;

  const rectificationsGrid = [];

  for (
    let i = 0;
    i < Math.max(numOfRectifications, numOfWantedRectifications);
    i++
  ) {
    rectificationsGrid.push(
      rectifications[i] ? rectifications[i] : blankRectification
    );
  }

  return (
    <div className={styles.rectificationsContainer}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Details of faults needing rectification</th>
            <th>IM No.</th>
            <th>Action taken to rectify faults</th>
            <th>Rectified by</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(rectificationsGrid).map((id) => (
            <tr key={id}>
              <td>{rectificationsGrid[id].description}</td>
              <td style={{ textAlign: "center" }}>
                {rectificationsGrid[id].fault.im_no}
              </td>
              <td>{rectificationsGrid[id].action.name}</td>
              <td>{getInitials(rectificationsGrid[id].rectifier)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const InspectionForm = ({ faultsArray, faults, report, vehicle, user }) => {
  const infoFieldsData = {
    Company: vehicle?.company?.name || user?.employee?.account?.company_name,
    Address:
      vehicle?.company?.address || user?.employee?.account?.contact_address,
    Make: vehicle?.make,
    "Reg no": vehicle?.reg,
    Model: vehicle?.model,
    "Fleet no": vehicle?.fleet_no,
    "Body type": vehicle?.body_type,
    "Odo reading": `${report?.odometer?.km} km`,
    Year: vehicle?.year,
    "Chassis no": vehicle?.chassis_no,
    "Tachograph calibration date": dateToDDMMYYYY(
      report?.tachograph?.next_calibration_date
    ),
    "Inspection date": dateToDDMMYYYY(report?.inspection_date),
    "ISO week no": report?.inspection_date
      ? getISOWeek(new Date(report.inspection_date))
      : "",
  };
  return (
    <div className={styles.container}>
      <h1>{report?.report_type?.name}</h1>
      <div className={styles.infoContainer}>
        {Object.keys(infoFieldsData).map((fieldLabel, index) => (
          <MakeInfoComponent
            key={index}
            label={fieldLabel}
            value={infoFieldsData[fieldLabel]}
          />
        ))}
      </div>
      <h2 className={styles.partTitle}>Part 1: Inspection</h2>
      <MakeChecksInCategories
        checks={
          report.checks?.length > 0
            ? report.checks
            : faultsArray.map((fault) => ({
                fault: fault.id,
                marking_code: "NC",
              }))
        }
        faults={faults}
        tyres={makeReportTyreChecksDefault(report.tyres)}
      />
      <h2 className={styles.partTitle}>Part 2: Rectification</h2>
      <MakeRectifications rectifications={report.rectifications || []} />
      <MakeCheckedComponent
        name={getNameOrBlank(report.inspector)}
        date={
          report.inspection_date ||
          REPORT_DEFAULT_STATE.checkedInfo.checkedDate.date
        }
        title="Inspected by: "
      />
      <MakeCheckedComponent
        name={getNameOrBlank(report.checker)}
        date={
          report.checked_date ||
          REPORT_DEFAULT_STATE.reportInfo.inspectionDate.date
        }
        title="Checked by: "
      />
    </div>
  );
};

const getNameOrBlank = (user) =>
  user?.first_name || user?.last_name
    ? `${user.first_name} ${user.last_name}`
    : "_".repeat(20);

const mapStateToProps = (state, ownProps) => {
  return {
    faults: selectFaults(state),
    faultsArray: selectFaultsAsArray(state),
    user: selectUser(state),
  };
};

export default connect(mapStateToProps)(InspectionForm);
