import React from "react";
import { TextField } from "@material-ui/core";
import styles from "./styles.module.css";
import TextInput from "../textinput";

const Tyre = ({ currentTyre, handleChange, setFunction, style, tyre }) => {
  return (
    <div className={styles.tyreOuterContainer}>
      <div style={style} className={styles.tyreInnerContainer}>
        <TextInput
          size="small"
          type="number"
          defaultValue={tyre.mm || ""}
          onBlur={(event) => {
            let value = event.target.value && event.target.value;
            let isEditing = parseFloat(value) !== parseFloat(currentTyre?.mm);

            handleChange(
              `tyre${tyre.tyre_number}.mm`,
              () =>
                setFunction({
                  ...tyre,
                  mm: value === "" ? null : value,
                }),
              isEditing
            );
          }}
          label="mm"
          type="text"
        />
        <TextInput
          size="small"
          className={styles.input}
          type="number"
          defaultValue={tyre.psi || ""}
          onBlur={(event) => {
            let value = event.target.value && event.target.value;
            let isEditing = value !== currentTyre?.psi;

            handleChange(
              `tyre${tyre.tyre_number}.psi`,
              () =>
                setFunction({
                  ...tyre,
                  psi: value === "" ? null : value,
                }),
              isEditing
            );
          }}
          label="psi"
          type="text"
        />
      </div>
      <div>
        <TextInput
          size="small"
          className={styles.input}
          defaultValue={tyre.age || ""}
          onBlur={(event) => {
            let value = event.target.value && event.target.value;
            let isEditing = value !== currentTyre?.age;

            handleChange(
              `tyre${tyre.tyre_number}.age`,
              () =>
                setFunction({
                  ...tyre,
                  age: value === "" ? null : value,
                }),
              isEditing
            );
          }}
          label="age"
          type="text"
        />
      </div>
    </div>
  );
};

function TyreCheck({ current, handleChange, setFunction, tyreChecks }) {
  return (
    <div className={styles.container}>
      <div>Tread wear / pressure</div>
      <div className={styles.tyresContainer}>
        <div className={styles.onVehicleTyres}>
          {tyreChecks.slice(0, tyreChecks.length - 1).map((tyre, index) => (
            <Tyre
              key={index}
              style={{ gridArea: `tyre${tyre.tyre_number}` }}
              currentTyre={current && current[index]}
              tyre={tyre}
              handleChange={handleChange}
              setFunction={(tyre) => {
                const before = tyreChecks.slice(0, index);
                const after = tyreChecks.slice(index + 1, tyreChecks.length);
                setFunction([...before, tyre, ...after]);
              }}
            />
          ))}
        </div>
        <div className={styles.offVehicleTyres}>
          <Tyre
            tyre={tyreChecks[tyreChecks.length - 1]}
            currentTyre={current && current[tyreChecks.length - 1]}
            handleChange={handleChange}
            setFunction={(tyre) => {
              const before = tyreChecks.slice(0, tyreChecks.length - 1);
              setFunction([...before, tyre]);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TyreCheck;
